import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useLazyGetLocationQuery } from '../../store/services/locationApi';
import { useLazyGetBatchesQuery } from '../../store/services/batchApi';
import { useLazyGetTagQuery } from '../../store/services/tagsApi';
import { Html5Qrcode } from 'html5-qrcode';
import { IMultiSelectOptions } from '../../types';
import { IExternalId } from '../../store/services/tagsApi/tagTypes';
import { skippedScannerExceptions } from 'src/constants/skippedScannerExceptions';
import { companiesMapping, externalCompaniesMapping } from 'src/constants/companiesMapping';

import './styles.module.css';

interface IScanner {
  scannerMode?: IScannerMode;
  selectedAssets?: IMultiSelectOptions;
  selectedExternalIds?: IMultiSelectOptions;
  setFieldValue?: any;
  onClose?: () => void;
}

export type IScannerMode = 'asset' | 'batch' | 'location' | 'assetMultiselect' | 'assetExternal'| 'serialAssets';

const Scanner = ({
  scannerMode = 'asset',
  selectedAssets,
  selectedExternalIds,
  setFieldValue,
  onClose,
}: IScanner) => {
  const [triggerLocationByTag, { data: locationData, isError: isLocationError }] =
    useLazyGetLocationQuery();
  const [triggerBatchByTag, { data: batchData, isError: isBatchError }] = useLazyGetBatchesQuery();
  const [triggerTag, { data: tagData }] = useLazyGetTagQuery();
  const history = useHistory();
  const html5QrCodeRef = useRef<Html5Qrcode | null>(null);
  const [scannerError, setScannerError] = useState('');

  const extractIdentifier = (url: string): string | IExternalId | null => {
    if (Object.keys(companiesMapping).some((companyUrl) => url.includes(companyUrl))) {
      const identifier = url.split('/');
      const id = identifier[identifier.length - 1];

      let result: string | IExternalId = id;

      Object.entries(externalCompaniesMapping).forEach(([externalSystemUrl, externalSystem]) => {
        if (url.includes(externalSystemUrl)) {
          result =
            scannerMode === 'assetExternal'
              ? {
                  systemName: externalSystem,
                  id,
                  urlStringFormat: externalSystemUrl,
                }
              : `${id}?externalSystem=${externalSystem}`;
        }
      });
      return result;
    }
    return null;
  };

  useLayoutEffect(() => {
    html5QrCodeRef.current = new Html5Qrcode('reader');
  }, []);

  useEffect(() => {
    if (isBatchError || isLocationError) {
      setScannerError(
        `Could not find ${
          scannerMode !== 'assetMultiselect' ? scannerMode : 'asset'
        } with that tag`,
      );
    }
  }, [isBatchError, isLocationError, setScannerError, scannerMode]);

  useEffect(() => {
    const config = {
      fps: 5,
      qrbox: (scannerWidth: number, scannerHeight: number) => {
        const qrboxWidth = Math.max(scannerWidth * 0.7, 50);
        const qrboxHeight = Math.max(scannerHeight * 0.8, 50);

        return {
          width: qrboxWidth,
          height: qrboxHeight,
        };
      },
    };

    const handleScannerFailure = (error: string) => {
      if (!skippedScannerExceptions[error]) {
        setScannerError(error);
      }
    };

    const startScan = async () => {
      try {
        await html5QrCodeRef.current?.start(
          { facingMode: 'environment' },
          config,
          handleScannerSuccess,
          handleScannerFailure,
        );
      } catch (error: any) {
        setScannerError(error);
      }
    };

    const stopScan = () => {
      if (html5QrCodeRef.current && html5QrCodeRef.current.isScanning) {
        html5QrCodeRef.current.stop();
      }
    };

    const handleScannerSuccess = (decodedText: any) => {
      const id = extractIdentifier(decodedText);
      if (id) {
        stopScan();
        switch (scannerMode) {
          case 'batch':
            triggerBatchByTag({ tag: id }).then(({ data }) => !!data && setFieldValue(data));
            break;
          case 'location':
            triggerLocationByTag({ tag: id }).then(
              ({ data }) => !!data && history.push(`/location/${data[0].id}`),
            );
            break;
          case 'asset':
            history.push(`/tag/${id}`);
            break;
          case 'assetMultiselect':
            triggerTag({ tag: id }).then(({ data }) => {
              !!data &&
                selectedAssets &&
                setFieldValue &&
                setFieldValue([
                  ...selectedAssets,
                  {
                    label: data.name,
                    value: data.id,
                  },
                ]);
              onClose?.();
            });
            break;
          case 'serialAssets':
            triggerTag({ tag: id }).then(({ data }) => {
              !!data &&
                setFieldValue &&
                setFieldValue(
                  {
                    label: data.tag,
                    value: data.id,
                  },
                  data,
                );
              onClose?.();
            });
            break;
          case 'assetExternal':
            selectedExternalIds &&
              setFieldValue &&
              setFieldValue([
                ...selectedExternalIds,
                {
                  label: (id as IExternalId).id,
                  value: id as IExternalId,
                },
              ]);
            onClose?.();
            break;
        }
      } else {
        setScannerError(`Could not recognize asset tag in url ${decodedText}`);
        stopScan();
      }
    };

    startScan();

    return () => {
      stopScan();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    scannerMode,
    triggerLocationByTag,
    triggerBatchByTag,
    onClose,
    selectedAssets,
    selectedExternalIds,
    setFieldValue,
    triggerTag,
  ]);

  return (
    <div className="flex flex-col justify-center scanner w-full h-full">
      <div
        id="reader"
        className="absolute left-1/2 w-[350px] md:w-[650px] -translate-x-1/2 overflow-hidden"
      />
      {scannerError && (
        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50" role="alert">
          {scannerError}
        </div>
      )}
    </div>
  );
};

export default Scanner;
