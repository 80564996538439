import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyGetAssetQuery } from '../../../../../../store/services/assetsApi';
import { IMultiSelectOptions } from '../../../../../../types';
import { handleChangeMultiSelect } from '../../../../../../helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from '../../../../../CustomSelect';
import ScanButton from '../../../../../../views/Home/components/ScanButton';
import useDebounce from '../../../../../../hooks/useDebounce';
import { useLazyGetTagQuery } from '../../../../../../store/services/tagsApi';
import { ITagData } from '../../../../../../store/services/tagsApi/tagTypes';

interface IBatchTagsMultiselect {
  setFieldValue: any;
  placeholder: string; 
}

const BatchTagsMultiselect = ({ setFieldValue, placeholder }: IBatchTagsMultiselect) => {
  const [triggerAssetSearch, { data: filteredAssetData }] =
    useLazyGetAssetQuery();
  const [triggerAssetSearchByTag, { data: assetTagData }] =
    useLazyGetTagQuery();
  const [selectedAssets, setSelectedAssets] = useState<IMultiSelectOptions | []>([]);
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  const [debouncedSelectInputValue, selectInputValue, setDebouncedSelectInputValue] = useDebounce(
    '',
    800,
  );
  const [currentTagData, setCurrentTagData] = useState<ITagData | undefined>(undefined);
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const tagRegExp = useMemo(() => /^.{3}-.{3}$/, []);

  useEffect(() => {
    tagRegExp.test(debouncedSelectInputValue)
      ? triggerAssetSearchByTag({ tag: debouncedSelectInputValue }).then(({ data }) =>
          setCurrentTagData(data),
        )
      : triggerAssetSearch({
          ...(debouncedSelectInputValue ? { search: debouncedSelectInputValue } : {}),
          pageSize: 10,
        }).then(() => setCurrentTagData(undefined));
  }, [debouncedSelectInputValue, triggerAssetSearch, triggerAssetSearchByTag, tagRegExp]);

  const filteredTagOptions = useMemo(() => {
    return !currentTagData && filteredAssetData
      ? filteredAssetData.assets.map((tag) => {
          return { label: tag.name, value: tag.id };
        })
      : currentTagData
      ? [{ label: currentTagData.name, value: currentTagData.id }]
      : [];
  }, [filteredAssetData, currentTagData]);

  const handleScannerToggle = useCallback(() => setIsScannerOpen((prevState) => !prevState), []);

  const handleSelectChange = useCallback(
    (options: IMultiSelectOptions) => {
      handleChangeMultiSelect({ option: options, field: 'assetIds', setFieldValue });
      setSelectedAssets(options);
      setHasValue(!!options?.length); 
      setTimeout(() => {
        const element = document.getElementById('horizontal-scroll-container');
        element?.children[element?.children?.length - 1]?.scrollIntoView();
      });
    },
    [setFieldValue],
  );

  const handleSelectInputChange = useCallback(
    (inputValue: string) => {
      setDebouncedSelectInputValue(inputValue);
    },
    [setDebouncedSelectInputValue],
  );

  return (
    <div className="relative flex items-center gap-3 mt-[15px]">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={handleScannerToggle}
        handleScannerClose={handleScannerToggle}
        scannerMode="assetMultiselect"
        selectedAssets={selectedAssets}
        setFieldValue={handleSelectChange}
      />
      <div className="relative w-full">
        <CustomSelect
          placeholder="" 
          value={selectedAssets}
          options={filteredTagOptions}
          isMulti
          isSearchable
          onSelect={handleSelectChange}
          onInputChange={handleSelectInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          shouldDisableFilter={!!currentTagData}
          hasHorizontalScroll
          customStyle="w-full"
        />
        <label
          className={`absolute left-[10px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
            isFocused || hasValue ? '!-top-1 text-xs' : 'top-[50%] -translate-y-1/2 text-base'
          }`}
          style={{ width: 'calc(100% - 40px)' }} 
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default BatchTagsMultiselect;
