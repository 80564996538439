import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useEditBatchMutation } from '../../../store/services/batchApi';
import AddBatchModal from './AddBatchModal';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import ButtonWithIcon from '../../ButtonWithIcon';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';



type IOpenedModalType =
  | ''
  | 'batchExisting'
  | 'batchFromObjects'
  | 'createBatch';

const CreateBatchModal = () => {
  const { t } = useTranslation('layout');
  const [openedModalType, setOpenedModalType] = useState<IOpenedModalType>('');
  const [editBatch] = useEditBatchMutation();
  const history = useHistory();

  const handleCloseCreatingModal = useCallback(() => {
    setOpenedModalType('');
  }, []);

  
  return (
    <div>
     
        <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
          icon={<IconAdd className="w-6 h-6 fill-tag" />}
          text={t('batch-creation-text')}
          onClick={() => setOpenedModalType('createBatch')}
        />
      
    
      <AddBatchModal
        isOpened={openedModalType === 'batchExisting'}
        onClose={handleCloseCreatingModal}
      />
      <AddBatchModal
        isOpened={openedModalType === 'batchFromObjects'}
        onClose={handleCloseCreatingModal}
        creationType="fromExistingObjects"
      />
      <ConfirmationModal
        isOpen={openedModalType === 'createBatch'}
        onAccept={() => {
          setOpenedModalType('batchExisting');
        }}
        onCancel={() => {
          setOpenedModalType('batchFromObjects');
        }}
        onClose={() => {
          setOpenedModalType('');
        }}
        confirmationText={t('batch-creation-text')}
        confirmationAcceptText={t('batch-creation-new-text')}
        confirmationCancelText={t('batch-creation-existing-text')}
        confirmationAcceptColor="gray"
      />
    </div>
  );
};

export default React.memo(CreateBatchModal);
