import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomSelect from 'src/components/CustomSelect';
import { ISelectOptions } from 'src/types';
import { useAppSelector } from 'src/helpers/reduxCommon';
import { JwtDecodeUsers } from '../helpers/JwtDecodeUsers';

interface IOrganizationsSelect {
  isError?: boolean;
  errorMessage?: any;
  value?: string;
  setFieldValue: any;
  isClearable?: boolean;
  placeholder: string;
}

const OrganizationsSelect = ({
  isError,
  errorMessage,
  value,
  setFieldValue,
  isClearable,
  placeholder,
}: IOrganizationsSelect) => {
  const { t } = useTranslation('common');
  const token = useAppSelector((state) => state.auth.token);
  const organizationsOptions = useMemo(() => JwtDecodeUsers(token), [token]);

  const isDisableCompaniesSelect = organizationsOptions.length === 1;

  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(!!value);

  const handleChangeSelect = (option: ISelectOptions) => {
    setFieldValue('organization', option?.value);
    setHasValue(!!option?.value); 
  };

  useEffect(() => {
    if (isDisableCompaniesSelect) {
      setFieldValue('organization', organizationsOptions[0].value);
      setHasValue(true); 
    }
  }, [isDisableCompaniesSelect, organizationsOptions, setFieldValue]);

  const selectValue = organizationsOptions.find((item) => item.value === value);

  return (
    <div className="relative mt-[15px]">
      <CustomSelect<ISelectOptions>
        isClearable={isClearable}
        isDisabled={isDisableCompaniesSelect}
        options={organizationsOptions}
        onSelect={handleChangeSelect}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder="" 
        isError={isError}
        errorMessage={errorMessage}
        value={selectValue}
      />
      <label
        className={`absolute left-[10px] top-[9px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-4 text-xs' : 'text-base'
        }`}
      >
        {placeholder}
      </label>
    </div>
  );
};

export default React.memo(OrganizationsSelect);
