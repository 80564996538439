import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelete } from 'src/assets/footer-menu/IconDelete.svg';
import { useDeleteAssetMutation } from '../store/services/assetsApi'; 
import { useDeleteLocationMutation } from '../store/services/locationApi';
import { useHistory } from 'react-router-dom'; 
import { ROUTES } from '../constants/routes';

type IOpenedModalType = '' | 'deleteAsset' | 'deleteLocation'; 

interface IDangerzoneProps {
  assetId: number | undefined;
  editLocationData?: any;
}

const Dangerzone: React.FC<IDangerzoneProps> = ({ assetId, editLocationData }) => {
  const { t } = useTranslation('tag');
  const [openedModalType, setOpenedModalType] = useState<IOpenedModalType>('');
  const [deleteAssetById] = useDeleteAssetMutation();
  const [deleteLocationById] = useDeleteLocationMutation(); 
  const history = useHistory(); 

  const handleDeleteClick = (event: React.MouseEvent) => {
    event.preventDefault(); 
    setOpenedModalType('deleteAsset'); 
  };

  const handleConfirmDelete = () => {
    switch (openedModalType) {
      case 'deleteAsset':
        if (assetId) {
          deleteAssetById(assetId); 
        }
        setOpenedModalType('');
        break;
      case 'deleteLocation':
        if (editLocationData?.id) {
          deleteLocationById(editLocationData.id); 
        }
        setOpenedModalType(''); 
        history.push(ROUTES.LOCATION);
        break;
      default:
        break;
    }
  };

  const handleCancelDelete = () => {
    setOpenedModalType(''); 
  };

  return (
    <div className="mt-6 p-4 border border-red-500 rounded-lg bg-white w-full max-w-none">
      <h2 className="text-lg font-semibold mb-3 text-red-600">
        {t('dangerzone-label')}
      </h2>

      <div className="flex justify-between items-center p-4 border border-gray-300 rounded-lg mb-2 bg-white">
        <div>
          <p className="font-medium text-gray-800">
            {t('delete-asset-label')}
          </p>
          <p className="text-sm text-gray-500">
            {t('delete-description')}
          </p>
        </div>

        <button
          type="button" 
          onClick={handleDeleteClick} 
          className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white font-semibold rounded hover:bg-red-700 transition duration-200"
        >
          <IconDelete className="w-5 h-5 fill-white" />
          {t('delete-label')}
        </button>
      </div>

      {openedModalType === 'deleteAsset' && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div 
            className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm"
            onClick={(e) => e.stopPropagation()} 
          >
            <h3 className="text-lg font-semibold mb-3">
              {t('confirm-delete-title')}
            </h3>
            <p className="mb-5 text-gray-700">
              {t('confirm-delete-text')}
            </p>
            <div className="flex justify-end gap-4"> 
              <button
                type="button"
                onClick={handleCancelDelete}
                className="px-4 py-2 bg-gray-300 text-gray-800 font-semibold rounded hover:bg-gray-400 transition duration-200"
              >
                {t('cancle-label')}
              </button>
              <button
                type="button"
                onClick={handleConfirmDelete} 
                className="px-4 py-2 bg-red-600 text-white font-semibold rounded hover:bg-red-700 transition duration-200"
              >
                {t('delete-label')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dangerzone;
