import React from 'react';
import { ReactComponent as IconQR } from '../../../assets/home/IconQR.svg';
import { IMultiSelectOptions } from '../../../types';
import ModalContainer from '../../../components/Modals/components/ModalContainer';
import useOpenScanner from 'src/hooks/useOpenScanner';
import Scanner, { IScannerMode } from '../../../components/Scanner/Scanner';
import { useTranslation } from 'react-i18next';

interface IScanButtonProps {
  isOpen: boolean;
  handleScannerOpen: () => void;
  handleScannerClose: () => void;
  scannerMode?: IScannerMode;
  selectedAssets?: IMultiSelectOptions;
  selectedExternalIds?: IMultiSelectOptions;
  setFieldValue?: any;
  scanBtnClassName?: string;
  iconClassName?: string;
  buttonText?: string; 
  variant?: 'default' | 'location' | 'batch'; 
}

const ScanButton = ({
  isOpen,
  handleScannerOpen,
  handleScannerClose,
  scannerMode,
  selectedAssets,
  selectedExternalIds,
  setFieldValue,
  iconClassName = '',
  scanBtnClassName = '',
  buttonText = '', 
  variant = 'default', 
}: IScanButtonProps) => {
  const { t } = useTranslation();
  useOpenScanner({ openScanner: handleScannerOpen });

  const buttonStyles = 
    variant === 'location' || variant === 'batch'
      ? 'flex items-center gap-[10px] max-w-[188px] min-h-[50px] py-[10px] px-5 bg-tag text-white border-none rounded text-lg leading-normal text-center !outline-transparent transition-colors duration-300 ease-linear cursor-pointer'
      : 'h-[50px] w-[50px] m-[10px] p-[1px] bg-white rounded-md inline-block transition-colors duration-300 ease-in-out cursor-pointer';

  const iconStyles = 
    variant === 'location' || variant === 'batch'
      ? 'w-12 h-12 fill-white'
      : 'w-12 h-12 fill-scan-qr-btn-color';

  return (
    <>
      <div
        onClick={handleScannerOpen}
        data-modal-show="default-modal"
        className={`${buttonStyles} ${scanBtnClassName}`}
      >
        <IconQR className={`${iconStyles} ${iconClassName}`} />
        <span className="text-sm" style={{ color: 'white', fontSize: '16px' }}>
          {buttonText} 
        </span>
      </div>

      <ModalContainer isModalOpened={isOpen} onClose={handleScannerClose}>
        <Scanner
          scannerMode={scannerMode}
          setFieldValue={setFieldValue}
          onClose={handleScannerClose}
          selectedAssets={selectedAssets}
          selectedExternalIds={selectedExternalIds}
        />
      </ModalContainer>
    </>
  );
};

export default React.memo(ScanButton);
