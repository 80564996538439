import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth.slice';
import activeOrgSlice from './slices/activeOrg.slice';
import { apiRtk } from './services';

const reducer = combineReducers({
  auth: authSlice,
  activeOrg: activeOrgSlice,
  [apiRtk.reducerPath]: apiRtk.reducer,
});
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([apiRtk.middleware]),
  devTools: process.env.NODE_ENV === 'development',
});

export default store;
