import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { REQUEST } from 'src/store/storeTypes';
import { apiRtk, RTK_TAGS } from '../';
import { IBatchData, ICreateBatchBody, IPatchBatchBody } from './batchTypes';

export const batchApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getBatches: build.query({
      query: (params) => ({
        url: '/batch',
        method: REQUEST.GET,
        params,
      }),
      transformResponse: (response: IBatchData[]) => response,
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      providesTags: () => [{ type: RTK_TAGS.BATCH }],
    }),
    getBatchById: build.query({
      query: (batchId: string) => ({
        url: `/batch/${batchId}`,
        method: REQUEST.GET,
      }),
      transformResponse: (response: IBatchData) => response,
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      providesTags: () => [{ type: RTK_TAGS.BATCH_BY_ID }],
    }),
    createBatch: build.mutation({
      query: ({ body, headers }: { body: ICreateBatchBody; headers?: Record<string, string> }) => ({
        url: '/batch',
        method: REQUEST.POST,
        body,
        ...(headers ? { headers } : {}),
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
        return error;
      },
      invalidatesTags: () => [{ type: RTK_TAGS.BATCH }, { type: RTK_TAGS.EVENTS }],
    }),
    editBatch: build.mutation({
      query: ({ batchId, body, headers }: IPatchBatchBody) => ({
        url: `/batch/${batchId}`,
        method: REQUEST.PATCH,
        body,
        headers,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      invalidatesTags: () => [{ type: RTK_TAGS.BATCH_BY_ID }, { type: RTK_TAGS.EVENTS }],
    }),
    editBatchAssets: build.mutation({
      query: ({ batchId, body, headers }: IPatchBatchBody) => ({
        url: `/batch/${batchId}`,
        method: REQUEST.POST,
        body,
        headers,
      }),
      transformErrorResponse: (error, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
      invalidatesTags: () => [{ type: RTK_TAGS.BATCH_BY_ID }, { type: RTK_TAGS.EVENTS }],
    }),
  }),
});

export const {
  useGetBatchesQuery,
  useGetBatchByIdQuery,
  useCreateBatchMutation,
  useEditBatchMutation,
  useEditBatchAssetsMutation,
  useLazyGetBatchesQuery,
} = batchApi;
