import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useEditAssetMutation } from '../../../../../store/services/assetsApi';
import { getXAuthOwnerHeader } from '../../../../../helpers/getXAuthOwnerHeader';
import { ISelectOptions } from '../../../../../types';
import { ITagData } from '../../../../../store/services/tagsApi/tagTypes';
import { useTranslation } from 'react-i18next';
import LocationSelect from '../../../Tag/components/TagForm/components/LocationSelect';
import CategoriesSelect from '../../../Tag/components/TagForm/components/CategoriesSelect';
import TagStateSelect from '../../../Tag/components/TagForm/components/TagStateSelect';
import OrganizationsSelect from '../../../../OrganizationsSelect';
import SerialAssetsMenu from './components/SerialAssetsMenu';
import FormWrapper from '../../../../FormWrapper';

const SerialAssetsForm = () => {
  const [scannedTagData, setScannedTagData] = useState<ITagData | null>(null);
  const [editAsset] = useEditAssetMutation();
  const [selectedTag, setSelectedTag] = useState<ISelectOptions | null>(null);
  const { t } = useTranslation('tag');

  const initialValues: any = {
    state: '',
    categories: null,
    location: null,
    organization: '',
    assetId: scannedTagData?.id || null,
  };

  const { values, handleSubmit, setFieldValue, errors, touched } = useFormik({
    onSubmit: (values) => {
      const newAssetBody = {
        state: values.state,
        categories: values.categories,
        locationId: values?.location?.id,
      };
      editAsset({
        body: newAssetBody,
        assetId: values.assetId,
        ...getXAuthOwnerHeader(values.organization),
      }).then(() => {
        setSelectedTag(null);
        setScannedTagData(null);
      });
    },
    initialValues,
  });

  console.log("hleoo");
  console.log(values.categories)
  useEffect(() => {
    setFieldValue('assetId', scannedTagData?.id);
  }, [scannedTagData?.id, setFieldValue]);

  return (
    <>
      <FormWrapper onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <LocationSelect location={values.location} setFieldValue={setFieldValue} />
          <CategoriesSelect categories={values.categories} setFieldValue={setFieldValue} />
          <TagStateSelect state={values.state} setFieldValue={setFieldValue} />
        </div>
        <OrganizationsSelect
          setFieldValue={setFieldValue}
          value={values.organization}
          isError={!!errors.organization && !!touched.organization}
          errorMessage={(touched.organization && errors?.organization) || ''}
          placeholder={t('select-organization-placeholder')}
        />
      </FormWrapper>
      <SerialAssetsMenu
        values={values}
        onSubmit={handleSubmit}
        selectedTag={selectedTag}
        setSelectedTag={setSelectedTag}
        setScannedTagData={setScannedTagData}
      />
    </>
  );
};

export default SerialAssetsForm;
