import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
  isAuth: boolean;
  isLoading: boolean;
  token: string | null;
}

const initialState: IInitialState = {
  isAuth: false,
  isLoading: true,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isLoading = false;
    },
  },
});


export const { setAuthToken } = authSlice.actions;

export default authSlice.reducer;
