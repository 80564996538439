import React from 'react';
import { useHistory } from 'react-router';
import { IBatchData } from '../../../../../store/services/batchApi/batchTypes';
import { useTranslation } from 'react-i18next';

interface IBatchesBadge {
  batches: IBatchData[];
}

const BatchesBadge = ({ batches }: IBatchesBadge) => {
  const history = useHistory();

  const { t } = useTranslation('tag');

  const handleBatchClick = (batchId: number) => {
    history.push(`/batch/${batchId}`);
  };

  return (
    <div className="flex flex-wrap gap-2">
      {!!batches?.length &&
        batches.map((batch, index) => (
          <button
            type="button"
            onClick={() => handleBatchClick(batch.id)}
            className="block py-1 px-2 my-2 mx-0.5
            bg-batch-badge
            transition-colors duration-300 ease-in-out
            text-xs text-batch-badge-foreground-color text-center
            rounded-xl
            cursor-pointer"
            key={`${index}batch`}
          >
             {t(`batch`)}:&nbsp;{batch.name?.replace(/ /g, '\u00a0')}
          </button>
        ))}
    </div>
  );
};

export default React.memo(BatchesBadge);
