import React, { useEffect, useState } from 'react';
import { useGetLocationQuery } from '../../store/services/locationApi';
import Loading from '../../components/Loading';
import ScanButton from '../Home/components/ScanButton';
import LocationsTable from './components/LocationsTable/index';
import LocationsFilter from './components/LocationsTable/components/LocationsFilter';
import { ILocation } from '../../store/services/locationApi/locationTypes';
import AddLocationModal from '../../components/Modals/Location/AddLocation';
import ButtonWithIcon from '../../components/ButtonWithIcon';
import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import { useTranslation } from 'react-i18next'; 



const Location = () => {
  const { data, isLoading } = useGetLocationQuery({});
  const [filteredLocations, setFilteredLocations] = useState<ILocation[] | undefined>(undefined);
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const { t } = useTranslation(); 

  useEffect(() => {
    if (!isLoading) {
      setFilteredLocations(data);
    }
  }, [isLoading, data]);

  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const handleOpenScanner = () => setIsScannerOpened(true);
  const handleCloseScanner = () => setIsScannerOpened(false);


  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex flex-col space-y-4">
        <LocationsFilter data={data} setLocations={setFilteredLocations} />
        <div className="flex items-center space-x-4">
          <ScanButton
            isOpen={isScannerOpened}
            handleScannerOpen={handleOpenScanner}
            handleScannerClose={handleCloseScanner}
            scannerMode="location"
            buttonText={t('scanner-location-button', { ns: 'tag' })}
            variant='location'
          />
        </div>
      </div>
      {filteredLocations && <LocationsTable data={filteredLocations} />}

      <ButtonWithIcon
          className="flex items-center justify-center gap-1 py-[10px] px-5   rounded text-center text-tag border-2 border-solid border-tag transition-colors duration-300 ease-linear m-2"
          icon={<IconAdd className="w-6 h-6 fill-tag" />}
          text={t('create-location', { ns: 'location' })}
          onClick={() => setIsModalOpened(true)}
        />

      <AddLocationModal
        isOpened={isModalOpened}
        onClose={() => setIsModalOpened(false)}
      />
    </div>
  );
};

export default React.memo(Location);
