import { useState } from 'react';
import { useGetEventsQuery } from 'src/store/services/eventsApi';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Pagination } from 'flowbite-react';
import { IEvent } from 'src/store/services/eventsApi/eventsTypes';
import EventsFilter from './components/EventsFilter';
import EventRow from './components/EventRow';
import { ServerError } from '../../../../store/storeTypes';

interface IEvents {
  defaultId?: string;
  pageSize?: number;
  defaultType?: string;
  showFilters?: boolean;
  showGoToEventsBtn?: boolean;
  rowClassNames?: string;
  displayType?: boolean;
}

const EventsTable = ({
  defaultId = '',
  pageSize = 15,
  defaultType = '',
  showFilters = false,
  showGoToEventsBtn = true,
  displayType = true,
  rowClassNames,
}: IEvents) => {
  const { t } = useTranslation('events');
  const [currentPage, setCurrentPage] = useState(1);
  const [id, setId] = useState(defaultId);
  const [type, setType] = useState(defaultType);
  const [action, setAction] = useState('');
  const history = useHistory();

  const { data, isError, error } = useGetEventsQuery({
    pageSize,
    pageOffset: currentPage,
    ...(id ? { id } : {}),
    ...(type ? { type } : {}),
    ...(action ? { action } : {}),
  });

  const handleGoEventsBtnClick = () => {
    history.push(`/events?defaultType=${defaultType}&defaultId=${defaultId}`);
  };

  return (
    <>
      {showFilters && (
        <EventsFilter
          events={data?.events || []}
          id={id}
          setId={setId}
          type={type}
          setType={setType}
          action={action}
          setAction={setAction}
          isError={isError}
          error={error as ServerError}
        />
      )}
      {!!data?.events.length && (
    <table className="w-full border-separate border-spacing-2 border border-slate-500 text-sm leading-normal !mt-5 mb-4">
          <tbody>
            {data?.events.map((event: IEvent, index: number) => (
              <EventRow key={index} event={event} classNames={rowClassNames} displayType={displayType}/>
            ))}
          </tbody>
        </table>
      )}
      {showGoToEventsBtn && (
        <Button
          onClick={handleGoEventsBtnClick}
          color="gray"
          size="sm"
          className="w-44 mx-auto mb-1 bg-gray-300"
        >
          {t('go-to-events-btn-text')}
        </Button>
      )}
      {data?.xPageTotal > 1 && (
        <div className="flex overflow-x-auto justify-center pb-16 lg:pb-2">
          <Pagination
            currentPage={currentPage}
            totalPages={data?.xPageTotal}
            onPageChange={setCurrentPage}
            previousLabel="&#60;&#60;"
            nextLabel="&#62;&#62;"
          />
        </div>
      )}
    </>
  );
};

export default EventsTable;
