import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IMultiSelectOptions } from 'src/types';
import { handleChangeMultiSelect } from 'src/helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from 'src/components/CustomSelect';
import { useGetBatchesQuery } from 'src/store/services/batchApi';
import { IBatchData } from 'src/store/services/batchApi/batchTypes';

interface IBatchesSelect {
  batches: IBatchData[];
  setFieldValue: any;
}

const BatchesSelect = ({ batches, setFieldValue }: IBatchesSelect) => {
  const { data, isSuccess } = useGetBatchesQuery({});
  const { t } = useTranslation('tag');

  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const batchOptions = useMemo(
    () => (isSuccess ? data.map((batch) => ({ value: batch, label: batch.name })) : []),
    [data, isSuccess],
  );

  const selectedBatchesValue = useMemo(
    () => batchOptions.filter((option) => batches?.find((batch) => batch.id === option.value.id)),
    [batchOptions, batches]
  );

  useEffect(() => {
    setHasValue(!!batches.length || !!selectedBatchesValue.length); 
  }, [batches, selectedBatchesValue]);

  const handleSelectChange = (option: IMultiSelectOptions) => {
    handleChangeMultiSelect({ option, field: 'batches', setFieldValue });
    setHasValue(!!option.length); 
  };

  return (
    <div className="relative">
      <CustomSelect
        placeholder=""
        value={selectedBatchesValue}
        options={batchOptions}
        isMulti
        onSelect={handleSelectChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <label
        className={`absolute left-[10px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-0 text-xs' : 'top-[50%] -translate-y-1/2 text-base'
        }`}
        style={{ width: 'calc(100% - 40px)' }}
      >
        {t('edit_tag_batches_placeholder')}
      </label>
    </div>
  );
};

export default React.memo(BatchesSelect);
