import React, { useCallback } from 'react';
import { ILocation } from '../../../../../store/services/locationApi/locationTypes';
import SearchField from '../../../../../components/SearchField';
import { useFormik } from 'formik';

interface ILocationsFilter {
  data: ILocation[] | undefined;
  setLocations: React.Dispatch<React.SetStateAction<ILocation[] | undefined>>;
}

const LocationsFilter = ({ data, setLocations }: ILocationsFilter) => {
  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => {
      handleSearch();
    },
  });

  const handleSearch = useCallback(
    () =>
      values.search
        ? setLocations(
            data?.filter(
              ({ name, address, description }) =>
                name?.includes(values.search) ||
                address?.postalCode?.includes(values.search) ||
                address?.city?.includes(values.search) ||
                address?.streetAddress?.includes(values.search) ||
                address?.country?.includes(values.search) ||
                description?.includes(values.search),
            ),
          )
        : setLocations(data),
    [data, values.search, setLocations],
  );

  return (
    <form onSubmit={handleSubmit} className="w-full">
      <SearchField name="search" onChange={handleChange} value={values.search} 
        wrapperClassName='rounded-[4px] border-[1px] ' />
    </form>
  );
};

export default LocationsFilter;
