import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useFormik, FormikProps } from 'formik';
import FormField from 'src/components/FormField';
import { IEditAssetBodyData } from 'src/store/services/assetsApi/assetsTypes';
import { IDocument, ITagData } from 'src/store/services/tagsApi/tagTypes';
import { useTranslation } from 'react-i18next';
import { usePostAssetByIdMutation } from '../../../../../store/services/tagsApi';
import SaveButton from 'src/components/SaveButton';
import CloseButton from 'src/components/CloseButton';
import ModalTitle from 'src/components/Modals/components/ModalTitle';
import UploadDocuments from './components/UploadDocument';
import Dangerzone from 'src/components/Dangerzone';
import CustomAccordion from '../../../../../components/CustomAccordion';
import { createFormDataFile } from '../../../../../helpers/createFormDataFile';
import { getXAuthOwnerHeader } from 'src/helpers/getXAuthOwnerHeader';
import FormWrapper from 'src/components/FormWrapper';
import { TagValidation } from './tagValidation';
import OrganizationsSelect from 'src/components/OrganizationsSelect';
import ModalContainer from 'src/components/Modals/components/ModalContainer';
import BatchesSelect from './components/BatchesSelect';
import LocationSelect from './components/LocationSelect';
import CategoriesSelect from './components/CategoriesSelect';
import TagStateSelect from './components/TagStateSelect';
import { getTagInitialValues } from './getTagInitialValues';
import { ServerError } from 'src/store/storeTypes';
import { ReactComponent as IconCamera } from 'src/assets/footer-menu/IconCamera.svg';
import { ReactComponent as IconUpload } from 'src/assets/IconUpload.svg';
import ServerFormError from 'src/components/ServerFormError';
import ExternalIdsMultiselect from './components/ExternalIdsMultiselect';
import CategoryPropertyList from './components/CategoryPropertyList';
import CustomMDEditor from 'src/components/MDEditor';
import AssetPicture from './components/AssetPicture';
import { useGetCategoriesQuery } from 'src/store/services/categoryApi';
import { getLinkedProperties } from 'src/helpers/categoriesOptions';
import ImageScanner from 'src/components/ImageScanner';
import ButtonWithIcon from 'src/components/ButtonWithIcon';

type IOpenedModalType = '' | 'imageUploader';

interface ITagForm {
  title: string;
  onSubmit: any;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: any;
  editAssetData?: ITagData;
  isOpened: boolean;
  onClose: () => void;
  resetEditAsset?: () => void;
  setLocalTagImages?: React.Dispatch<React.SetStateAction<[] | IDocument[]>>;
  data?: ITagData;
}

const TagForm: React.FC<ITagForm> = ({
  title,
  onSubmit,
  isSuccess,
  isLoading,
  isError,
  error,
  isOpened,
  editAssetData,
  onClose,
  resetEditAsset,
  setLocalTagImages,
  data,
}) => {
  const { t } = useTranslation('tag');
  const [capturedImages, setCapturedImages] = useState<IDocument[]>([]);
  const [tagDocuments, setTagDocuments] = useState<IDocument[]>([]);
  const [openedModalType, setOpenedModalType] = useState<IOpenedModalType>('');
  const [postAssetById] = usePostAssetByIdMutation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const assetId = data?.id ?? 0; 
  const initialValues = getTagInitialValues(data);
  const { data: categoryList, isSuccess: isCategoriesSuccess } = useGetCategoriesQuery({});
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const [isUploadAccordionOpen, setIsUploadAccordionOpen] = useState<boolean>(true);

  const [linkedProperties, setLinkedProperties] = useState<any[]>([]); 
  const [externalIds, setExternalIds] = useState<any[]>([]);

  const linkedPropertiesMemo = useMemo(() => {
    return getLinkedProperties(
      [...(data?.categories || []), ...(data?.connectedCategories || [])],
      data?.properties || {},
      categoryList?.categoryTypes
    );
  }, [categoryList?.categoryTypes, data?.categories, data?.connectedCategories, data?.properties]);

  const formik: FormikProps<IEditAssetBodyData> = useFormik({
    initialValues,
    validationSchema: TagValidation(),
    onSubmit: (values) => {
      const batchesIds = values.batches?.map((batch) => batch.id) || [];
      const documentIds = tagDocuments.map((doc) => doc.id);

      const newAssetBody = {
        name: values.name,
        description: values.description,
        state: values.state,
        categories: values.categories,
        locationId: values.location?.id,
        properties: values.properties,
        ...(batchesIds.length ? { batches: batchesIds } : {}),
        ...(!values.externalIds?.length ? { deleteFields: ['externalIds'] } : {}),
        documentIds: [...documentIds, ...capturedImages.map((img) => img.id)],
      };

      onSubmit({
        body: newAssetBody,
        assetId: data?.id, 
        ...getXAuthOwnerHeader(values.organization),
      });
    },
  });

  useEffect(() => {
    if (data?.documents) {
      const { tagDocuments, tagImages } = data.documents.reduce(
        (acc: { tagDocuments: IDocument[]; tagImages: IDocument[] }, file: IDocument) => {
          if (file.documentType === 'IMAGE') {
            acc.tagImages.push(file);
          } else {
            acc.tagDocuments.push(file);
          }
          return acc;
        },
        { tagDocuments: [], tagImages: [] }
      );
      setCapturedImages(tagImages);
      setTagDocuments(tagDocuments);
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
      resetEditAsset && resetEditAsset();
    }
  }, [isSuccess, onClose, resetEditAsset]);

  const addLocalImage = useCallback(
    (img: string) => {
      setLocalTagImages?.((prevState) => [...prevState, { url: img, documentType: 'IMAGE' }]);
    },
    [setLocalTagImages],
  );

  const handleCloseCreatingModal = useCallback(() => {
    setOpenedModalType('');
  }, []);

  const handleUploadFileBtnClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const handleLoadFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const inputFile = ev.target.files ? ev.target.files[0] : null;
      console.log('Uploaded Document Object:', inputFile);
      const isImageFileType = inputFile?.type.startsWith('image');

      const onLoadFile = (formData: FormData) => {
        postAssetById({ assetId, body: formData });
      };

      createFormDataFile({
        documentType: isImageFileType ? 'IMAGE' : 'DOCUMENT',
        event: ev,
        onLoadFile,
        ...(isImageFileType && addLocalImage),
      });
    },
    [assetId, postAssetById, addLocalImage],
  );

  return (
    <ModalContainer isModalOpened={isOpened} onClose={onClose} isFormModal={true}>
      <ModalTitle title={title} />
      <FormWrapper onSubmit={formik.handleSubmit}>
          <FormField
            type="text"
            value={formik.values.name}
            name="name"
            placeholder={t('edit_tag_name_placeholder')}
            onChange={formik.handleChange}
            isError={!!formik.errors.name && !!formik.touched.name}
            errorMessage={(formik.touched.name && formik.errors?.name) || ''}
          />

          <div className="markdown-editor mb-8">
            <CustomMDEditor
              id="description-editor"
              label={t('edit_tag_description_placeholder')}
              value={formik.values.description || ''}
              onChange={(value) =>
                formik.handleChange({ target: { name: 'description', value } })
              }
            />
          </div>

          <div className="flex flex-col">
            <TagStateSelect state={formik.values.state} setFieldValue={formik.setFieldValue} />
            <BatchesSelect batches={formik.values.batches} setFieldValue={formik.setFieldValue} />
            <LocationSelect location={formik.values.location} setFieldValue={formik.setFieldValue} />
            <CategoriesSelect categories={formik.values.categories} setFieldValue={formik.setFieldValue} />
            <ExternalIdsMultiselect
              tagData={data}
              setFieldValue={formik.setFieldValue}
              placeholder={t('select-tags-placeholder')}
            />
            <CategoryPropertyList values={formik.values} setFieldValue={formik.setFieldValue} />
          </div>

          <OrganizationsSelect
            setFieldValue={formik.setFieldValue}
            value={formik.values.organization}
            isError={!!formik.errors.organization && !!formik.touched.organization}
            errorMessage={(formik.touched.organization && formik.errors.organization) || ''}
            placeholder={t('select-organization-placeholder')}
          />

          <AssetPicture
            assetId={assetId} 
            capturedImages={capturedImages}
            setCapturedImages={setCapturedImages}
            dataDocuments={data?.documents}
          />

          {(tagDocuments.length || linkedPropertiesMemo.length || data?.externalIds?.length) && (
            <UploadDocuments
              tagDocuments={tagDocuments}
              setTagDocuments={setTagDocuments}
              linkedProperties={linkedPropertiesMemo}
              externalIds={data?.externalIds || []}
              assetId={assetId}
              setLinkedProperties={setLinkedProperties}  // Pass setter
              setExternalIds={setExternalIds}  // Pass setter
            />
          )}

          <div className="flex justify-center my-20" >
            <SaveButton submitFunc={formik.submitForm} disabled={isLoading} />
            <CloseButton onClose={onClose} disabled={isLoading} />
          </div>



          <CustomAccordion
            isAccordionOpen={isUploadAccordionOpen}
            setIsAccordionOpen={setIsUploadAccordionOpen}
            accordionWrapperClassName=' my-10 '
            contentWrapperClassName='flex items-center justify-center'
            title={t('edit-upload-label', { ns: 'tag' })}
          >

          <ButtonWithIcon
            className="flex items-center justify-center border-green-700 text-green-700 gap-1 py-[10px] px-5  m-5 h-[52px] rounded text-lg leading-normal text-center border-2 border-solid transition-colors duration-300 ease-linear"
            icon={ <IconCamera className="w-5 h-5 mr-2 fill-green-700" />
            }
            text= {t('context-menu-take-photo-label', { ns: 'tag' })}
            onClick={() => setOpenedModalType('imageUploader')}
            />

          <ButtonWithIcon
            className="flex items-center justify-center border-green-700 text-green-700 gap-1 py-[10px] px-5  m-5 h-[52px] rounded text-lg leading-normal text-center border-2 border-solid transition-colors duration-300 ease-linear"
            icon={ <IconUpload className="w-5 h-5 mr-2 fill-green-700" />
            }
            text= {t('context-menu-upload-file-label', { ns: 'tag' })}
            onClick={() => handleUploadFileBtnClick()}
            />


          </CustomAccordion>
          <CustomAccordion
            isAccordionOpen={isAccordionOpen}
            setIsAccordionOpen={setIsAccordionOpen}
            title={t('dangerzone-label', { ns: 'tag' })}
            accordionWrapperClassName=' my-10 border-red-700'
            
          >
            <div className="mt-4">
              <Dangerzone assetId={assetId} />
            </div>
          </CustomAccordion>

          <ModalContainer
            isModalOpened={openedModalType === 'imageUploader'}
            onClose={handleCloseCreatingModal}
          >
            <ImageScanner
              onClose={handleCloseCreatingModal}
              addLocalImage={addLocalImage}
              assetId={assetId} 
            />
          </ModalContainer>

          <input ref={fileInputRef} className="hidden" type="file" onChange={handleLoadFile} />
          <ServerFormError
            title={t('error-request')}
            requestError={error as ServerError}
            isError={isError}
          />
      </FormWrapper>
    </ModalContainer>
  );
};

export default React.memo(TagForm);
