import React from 'react';
import { useTranslation } from 'react-i18next';

interface ICategoryRow {
  propertyName: string;
  propertyValue?: string;
  classNames?: string;
}

const CategoryRow = ({ propertyName, propertyValue, classNames }: ICategoryRow) => {
  const { t } = useTranslation('tag');
  const extractDomain = (url: string) => { try { return new URL(url).hostname; } catch (e) { return ""; }}

  const hasLink = propertyValue
    ? propertyValue.startsWith('http://') || propertyValue.startsWith('https://')
    : false;
    
  const extractedLink = hasLink ? extractDomain(propertyValue as string) : "";
      
  return (
   
      propertyValue ? (
        <tr       className={`font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-300 ${classNames}`}
> 
<td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
        {t(`table-property-${propertyName}`, { defaultValue: propertyName })}
      </td>
        <td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
          {!hasLink ? (
            propertyValue
          ) : (
            
            <a href={propertyValue} target="_blank" rel="noreferrer">
              {extractedLink}
            </a>
          )}
        </td>
        </tr>
      ) : null
  );
};

export default CategoryRow;
