import React from 'react';
import EventsTable from './components/EventsTable';
import ContextMenuModal from '../../components/Modals/ContextMenuModal';
import { useLocation } from 'react-router';

const Events = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultType = queryParams.get('defaultType') || '';
  const defaultId = queryParams.get('defaultId') || '';
  return (
    <div className="p-4 space-y-4">
      <EventsTable
        showFilters
        showGoToEventsBtn={false}
        defaultType={defaultType}
        defaultId={defaultId}
      />
    </div>
  );
};

export default Events;
