import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelete } from 'src/assets/footer-menu/IconDelete.svg';
import { ReactComponent as IconLink } from 'src/assets/IconLink.svg';
import { ReactComponent as LoadFileIcon } from 'src/assets/upload_file_filled.svg';
import { IDocument, IExternalId } from 'src/store/services/tagsApi/tagTypes';
import { ILinkedProperty } from 'src/helpers/categoriesOptions';

interface ILoadDocuments {
  tagDocuments: IDocument[];
  setTagDocuments: React.Dispatch<React.SetStateAction<IDocument[]>>;
  linkedProperties: ILinkedProperty[];
  setLinkedProperties: React.Dispatch<React.SetStateAction<ILinkedProperty[]>>;
  externalIds: IExternalId[];
  setExternalIds: React.Dispatch<React.SetStateAction<IExternalId[]>>; 
  assetId: number;
}

const UploadDocuments = ({
  tagDocuments,
  setTagDocuments,
  linkedProperties,
  setLinkedProperties, 
  externalIds,
  setExternalIds, 
  assetId,
}: ILoadDocuments) => {
  const { t } = useTranslation();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`/assets/v1/asset/${assetId}`);
        if (!response.ok) throw new Error('Failed to fetch asset documents');
        const data = await response.json();
        const fetchedDocuments = data.documents.filter(
          (doc: IDocument) => doc.documentType !== 'IMAGE'
        );
        setTagDocuments(fetchedDocuments);
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [assetId, setTagDocuments]);

  const handleDeleteDocument = (documentToDelete: IDocument) => {
    setTagDocuments((prevDocs) =>
      prevDocs.filter((doc) => doc.url !== documentToDelete.url)
    );
  };

  const handleDeleteLinkedProperty = (propertyName: string) => {
    setLinkedProperties((prevProps) =>
      prevProps.filter((prop) => !Object.keys(prop).includes(propertyName))
    );
  };

  const handleDeleteExternalId = (externalIdToDelete: IExternalId) => {
    setExternalIds((prevIds) =>
      prevIds.filter((id) => id.id !== externalIdToDelete.id)
    );
  };

  const getFileName = (urlLink: string) => {
    const regex = /\/([^/?]+)\?/;
    const match = urlLink.match(regex);
    return match && match[1] ? match[1] : 'invalid name';
  };

  return (
    <div className="mt-4 p-4 border border-gray-300 rounded-lg">
      <label className="block text-gray-500 text-sm font-medium mb-4">
        {t('Uploaded Documents')}
      </label>
      <div className="flex flex-wrap gap-4 justify-center">
        {tagDocuments.map((document) => (
          <div key={document.url} className="w-48">
            <a
              href={document.url}
              download
              target="_blank"
              className="flex items-center gap-2 p-3 border border-gray-300 rounded-lg hover:bg-gray-100 w-full"
              rel="noreferrer"
            >
              <LoadFileIcon className="w-6 h-6" />
              <p className="text-sm truncate">{getFileName(document.url)}</p>
            </a>
            <div className="flex justify-center mt-2">
              <button
                onClick={() => handleDeleteDocument(document)}
                className="flex items-center gap-1 bg-red-500 text-white p-1 rounded"
                aria-label={`Delete document ${getFileName(document.url)}`}
              >
                <IconDelete className="w-4 h-4 fill-white" />
                <span className="text-sm">{t('Delete')}</span>
              </button>
            </div>
          </div>
        ))}

        {linkedProperties.map((property, index) => (
          <Fragment key={index}>
            {Object.entries(property).map(([propertyName, propertyLink]) => (
              <div className="w-48" key={propertyLink}>
                <a
                  href={propertyLink}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center gap-2 p-3 border border-gray-300 rounded-lg hover:bg-gray-100 w-full"
                >
                  <IconLink className="w-6 h-6" />
                  <p className="text-sm truncate">{propertyName}</p>
                </a>
                <div className="flex justify-center mt-2">
                  <button
                    onClick={() => handleDeleteLinkedProperty(propertyName)}
                    className="flex items-center gap-1 bg-red-500 text-white p-1 rounded"
                    aria-label={`Delete link ${propertyName}`}
                  >
                    <IconDelete className="w-4 h-4 fill-white" />
                    <span className="text-sm">{t('Delete')}</span>
                  </button>
                </div>
              </div>
            ))}
          </Fragment>
        ))}

        {externalIds.map((externalId) => {
          const externalIdUrl = externalId.urlStringFormat.slice(0, -4);
          const externalLink = `${externalIdUrl}${externalId.id}`;
          return (
            <div className="w-48" key={externalLink}>
              <a
                href={externalLink}
                download
                target="_blank"
                className="flex items-center gap-2 p-3 border border-gray-300 rounded-lg hover:bg-gray-100 w-full"
                rel="noreferrer"
              >
                <IconLink className="w-6 h-6" />
                <p className="text-sm truncate">{externalLink}</p>
              </a>
              <div className="flex justify-center mt-2">
                <button
                  onClick={() => handleDeleteExternalId(externalId)}
                  className="flex items-center gap-1 bg-red-500 text-white p-1 rounded"
                  aria-label={`Delete external link ${externalLink}`}
                >
                  <IconDelete className="w-4 h-4 fill-white" />
                  <span className="text-sm">{t('Delete')}</span>
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UploadDocuments;
