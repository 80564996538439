import React, { useCallback } from 'react';
import { ILocation } from '../../../../../store/services/locationApi/locationTypes';
import { useHistory } from 'react-router';

interface ILocationProps {
  data: ILocation;
}

const LocationRow = ({ data }: ILocationProps) => {
  const { address, name, description, id } = data;
  const history = useHistory();
  const handleOpenSingleLocation = useCallback(() => {
    history.push(`/location/${id}`);
  }, [id, history]);

  return (
    <tr
      onClick={handleOpenSingleLocation}
      className="font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-100"
    >
      <td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
        {name}
      </td>
      <td className="border border-solid border-[#ddd] text-[12px] text-[#555] p-[6px] text-left relative">
        {description}
      </td>
      <td className="border border-solid border-[#ddd] text-[10px] text-[#555] p-[6px] text-left relative">
        {address.streetAddress}
        <br />
        {address.postalCode} {address.city}
      </td>
    </tr>
  );
};

export default LocationRow;
