import React, { Fragment } from 'react';
import { ReactComponent as LoadFileIcon } from 'src/assets/upload_file_filled.svg';
import { ReactComponent as IconLink } from 'src/assets/IconLink.svg';
import { IDocument, IExternalId } from 'src/store/services/tagsApi/tagTypes';
import { ILinkedProperty } from '../../../../../../../../helpers/categoriesOptions';
import { useTranslation } from 'react-i18next';


interface ILoadDocuments {
  tagDocuments: IDocument[] | [];
  linkedProperties: ILinkedProperty[];
  externalIds: IExternalId[];
}

const LoadDocuments = ({ tagDocuments, linkedProperties, externalIds }: ILoadDocuments) => {
  const { t } = useTranslation('tag');

  const getFileName = (ulrLink: string) => {
    const regex = /\/([^/?]+)\?/;
    const match = ulrLink.match(regex);

    if (match && match[1]) {
      return match[1];
    } else {
      return 'invalid name';
    }
  };

  return (
    <table className="w-full border-separate border-spacing-2 border border-slate-500 text-sm leading-normal !mt-5 mb-4">
        <tbody>
      {tagDocuments?.map((document, index) => {
        const fileName = getFileName(document.url);
        return (
          <tr className={`font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-300 `} key={`${index}-${document.documentType}`}><td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative">
          <a
            href={document.url}
            download
            target="_blank"
            
            className="flex gap-[10px] "
            rel="noreferrer"
          >
            <span>
              <LoadFileIcon />
            </span>
            <p>{fileName}</p>
          </a></td></tr>
        );
      })}
      {linkedProperties.map((property, index) => {
        return (
          <Fragment key={`${property}-${index}`}>
            {Object.entries(property).map(([propertyName, propertyLink], index) => (
              <tr className={`font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-300 `} key={`${propertyLink}-${index}`}><td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative"> <a
                
                href={propertyLink}
                target="_blank"
                rel="noreferrer"
                className="flex gap-[10px]"
              >
                <span>
                  <IconLink />
                </span>
                <p>{t(`table-property-${propertyName}`, { defaultValue: propertyName })}</p>
              </a></td></tr>
            ))}
          </Fragment>
        );
      })}
      {externalIds.map((externalId, index) => {
        const externalIdUrl = externalId.urlStringFormat.substring(
          0,
          externalId.urlStringFormat.length - 4,
        );
        const externalLink = `${externalIdUrl}${externalId.id}`;
        return (
          <tr className={`font-['Mukta_Mahee',_Arial,_sans-serif] cursor-pointer even:bg-gray-300 `} key={`${index}-${externalLink}`}><td className="border border-solid border-[#ddd] text-base p-[6px] text-left relative"><a
            href={externalLink}
            download
            target="_blank"
            className="flex gap-[10px] "
            rel="noreferrer"
          >
            <span>
              <IconLink />
            </span>
            <p>{externalLink}</p>
          </a></td></tr>
        );
      })}
      </tbody>
    </table>
  );
};

export default LoadDocuments;