import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'flowbite-react';
import TagQRCode from './TagQRCode';

import { IExternalId } from 'src/store/services/tagsApi/tagTypes';
import LoopFrontLogo from '../../../../../assets/logo/Loopfront-logo.png';

interface IExternalLinksProps {
  externalIds?: IExternalId[];
}

const externalsLinksMapping = {
  loopfront: LoopFrontLogo,
};

const ExternalLinks = ({ externalIds }: IExternalLinksProps) => {
  return (

    <div className={'flex justify-center flex-col gap-2 p-0'}>
      {!!externalIds?.length &&
        externalIds.map((externalLink) => {
          const tagUrl = externalLink?.urlStringFormat.replace(/{id}/g, externalLink?.id);
          const asset =
            externalsLinksMapping[externalLink?.systemName as keyof typeof externalsLinksMapping];

          return (
            <Link
              key={`link-${externalLink?.id}`}
              target="_blank"
              to={{ pathname: tagUrl || '' }}
              rel="noopener noreferrer"
            >
              <TagQRCode tag={externalLink?.id} url={tagUrl} />

            </Link>

          );
        })}
    </div>
  );
};

export default React.memo(ExternalLinks);
