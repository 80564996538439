import React, { useCallback, useEffect } from 'react';
import { User } from '@auth0/auth0-spa-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../../../../components/ButtonWithIcon';
import { ReactComponent as IconLogout } from 'src/assets/footer-menu/IconLogout.svg';
import { useAppDispatch } from 'src/helpers/reduxCommon';
import { setActiveOrg } from 'src/store/slices/activeOrg.slice';
import { useAppSelector } from 'src/helpers/reduxCommon';
import Loading from '../../../../components/Loading'

import { useGetOrganizationQuery } from '../../../../store/services/configApi';
import { IOrganizationMap } from '../../../../store/services/configApi/configTypes';

interface IUserProfile {
}

const UserProfile = () => {

  const dispatch = useAppDispatch();

  const { data: organizationsData, isLoading: isOrganizationDataLoading } = useGetOrganizationQuery({});
  const getOrgName = (id: string | null) => id && (organizationsData as IOrganizationMap)[id as string] && (organizationsData as IOrganizationMap)[id as string].name;


  const { user, logout } = useAuth0() ;
  const logoutWithRedirect = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);
  const { name, nickname, picture, email } = user as User;
  const organizations = (user as User)['https://sirk.app/organizations'] as string[]
  const { t } = useTranslation('layout');
  const selectOwner = (org: string) => {
    console.log("Setting active org to " + org)
    dispatch(setActiveOrg(org));
  }

  const activeOrg = useAppSelector((state) => state.activeOrg.activeOrg);

  return !isOrganizationDataLoading ? (
    <div className="flex flex-wrap text-[#4C443C] !p-5 bg-indigo-200 rounded text-current leading-[27px] md:basis-1/2 mb-4 xl:mb-0 flex justify-center items-center">
      
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture m-5"
          />

          <p className="p-5">{t('welcome')}, <strong> {nickname ? nickname : name} </strong></p>
          
          
       
        <div className="p-8">{t('belong-to')}<ul className="list-disc mx-5"> {organizations.map((value, index) => 
          (<li key={index} onClick={()  => { selectOwner(value) }} className={"hover:cursor-pointer " + (value === activeOrg ? "font-bold" : "")}>{getOrgName(value)}</li>)
          )} </ul></div>

        <ButtonWithIcon
            className="flex items-center justify-center border-red-700 text-red-700 gap-1 py-[10px] px-5  m-5 h-[52px] rounded text-lg leading-normal text-center border-2 border-solid transition-colors duration-300 ease-linear"
            icon={<IconLogout className="w-6 h-6 fill-red-700 " />}
            text={t('log-out').replace(/ /g, '\u00a0')}
            onClick={logoutWithRedirect}
            />
      </div>
      
  )  : (
    <Loading />
  );
};

export default UserProfile;
