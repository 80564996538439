import React, { useCallback, useEffect, useState } from 'react';
import { useGetBatchesQuery } from '../../store/services/batchApi';
import Loading from '../../components/Loading';
import { IBatchData } from '../../store/services/batchApi/batchTypes';
import BatchesFilter from './components/BatchesFilter';
import BatchesTable from './components/BatchesTable';
import { useTranslation } from 'react-i18next';
import CreateBatchModal from '../../components/Modals/Batch/CreateBatchModal';

const Batches = () => {
  const [selectedStates, setSelectedStates] = useState<[] | string[]>(['PLANNED', 'IN_PROGRESS']);
  const { data, isLoading } = useGetBatchesQuery(
    selectedStates.length
      ? {
          states: selectedStates.join(),
        }
      : {},
  );
  const [filteredBatches, setFilteredBatches] = useState<IBatchData[] | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading) setFilteredBatches(data);
  }, [isLoading, data]);

  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const handleOpenScanner = () => setIsScannerOpened(true);
  const handleCloseScanner = () => setIsScannerOpened(false);

  const handleScanFilter = useCallback((data: IBatchData[]) => {
    setFilteredBatches(data);
    handleCloseScanner();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="p-4 space-y-4">
      <BatchesFilter
        batches={data}
        setBatches={setFilteredBatches}
        setSelectedStates={setSelectedStates}
        isScannerOpened={isScannerOpened} 
        handleOpenScanner={handleOpenScanner} 
        handleCloseScanner={handleCloseScanner} 
        handleScanFilter={handleScanFilter} 
      />
      {filteredBatches && <BatchesTable data={filteredBatches} />}
      <CreateBatchModal />
    </div>
  );
};

export default Batches;
