import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetBatchTypesQuery } from '../../../../../../store/services/configApi';
import { ISelectOptions } from 'src/types';
import CustomSelect from 'src/components/CustomSelect';
import ErrorField from 'src/components/ErrorField'; 

interface IBatchTypeSelect {
  type?: string;
  isError: boolean;
  errorMessage: string;
  setFieldValue: any;
}

const BatchTypeSelect = ({ type, isError, errorMessage, setFieldValue }: IBatchTypeSelect) => {
  const { t } = useTranslation('batches');
  const { data } = useGetBatchTypesQuery({});
  
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(!!type); 

  const typeOptions = data
    ? data.map((type) => ({
        value: type,
        label: t(`batch-type-${type.toLowerCase()}-option`),
      }))
    : [];

  const selectedTypeValue = typeOptions.find((option) => option.value === type);

  const handleChangeType = (option: ISelectOptions) => {
    setFieldValue('type', option?.value);
    setHasValue(!!option?.value); 
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    setHasValue(!!type);
  }, [type]);

  return (
    <div className="relative mt-[15px]">
      <label
        className={`absolute left-[10px] top-[9px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
          isFocused || hasValue ? '!-top-4 text-xs' : 'text-base'
        }`}
      >
        {t('batch-type-place-holder')}
      </label>

      <CustomSelect
        placeholder=""  
        value={selectedTypeValue}
        options={typeOptions}
        onSelect={handleChangeType}
        onFocus={handleFocus}  
        onBlur={handleBlur}     
        isError={isError}
        errorMessage={errorMessage}
      />

      <ErrorField errorMessage={errorMessage} />
    </div>
  );
};

export default React.memo(BatchTypeSelect);
