import React, { useState } from 'react';
import LocationRow from './components/LocationRow';
import { ILocation } from '../../../../store/services/locationApi/locationTypes';
import { Pagination } from 'flowbite-react';
import { useTranslation } from 'react-i18next';


interface ILocationsProps {
  data: ILocation[];
  pageSize?: number;
}

const LocationsTable = ({ data, pageSize = 15 }: ILocationsProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentData = data.slice(startIndex, endIndex);
  const { t } = useTranslation('location'); 

  return (
    <>
      <table className="w-full border-collapse text-sm leading-normal !mt-5">
        <tbody>
        <tr><th>{t('name')}</th><th>{t('description')}</th><th>{t('address')}</th></tr>

          {currentData.map((location: ILocation, index: number) => (
            <LocationRow key={index} data={location} />
          ))}
        </tbody>
      </table>
      {totalPages > 1 && (
        <div className="flex overflow-x-auto justify-center pb-16 md:pb-2">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            previousLabel="&#60;&#60;"
            nextLabel="&#62;&#62;"
          />
        </div>
      )}
    </>
  );
};

export default LocationsTable;
