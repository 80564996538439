import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import CustomSelect from '../../../../components/CustomSelect';
import { IMultiSelectOptions, IOptions } from '../../../../types';
import { IBatchData } from '../../../../store/services/batchApi/batchTypes';
import { batchTypeOptions } from '../../../../constants/batchTypeOptions';
import { getBatchStateOptions } from '../../../../constants/batchStateOptions';
import SearchField from '../../../../components/SearchField';
import ScanButton from '../../../Home/components/ScanButton'; 
import i18n from 'i18next';

interface IBatchesFilter {
  batches: IBatchData[] | undefined;
  setBatches: React.Dispatch<React.SetStateAction<IBatchData[] | undefined>>;
  setSelectedStates: React.Dispatch<React.SetStateAction<[] | string[]>>;
  isScannerOpened: boolean; 
  handleOpenScanner: () => void;
  handleCloseScanner: () => void; 
  handleScanFilter: (data: IBatchData[]) => void; 
}

const BatchesFilter = ({
  batches,
  setBatches,
  setSelectedStates,
  isScannerOpened,
  handleOpenScanner,
  handleCloseScanner,
  handleScanFilter
}: IBatchesFilter) => {
  const { t } = useTranslation('batches');
  const [selectedStateValue, setSelectedStateValue] = React.useState<IMultiSelectOptions>([
    { value: 'PLANNED', label: t('batch-state-planned-option') },
    { value: 'IN_PROGRESS', label: t('batch-state-in_progress-option') },
  ]);
  const [selectedTypeValue, setSelectedTypeValue] = React.useState<IMultiSelectOptions>();
  const [batchStateOptions, setBatchStateOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    const loadBatchStates = async () => {
      const options = await getBatchStateOptions();
      setBatchStateOptions(options);
    };

    loadBatchStates();

    const handleLanguageChange = () => {
      loadBatchStates();
      setSelectedStateValue([
        { value: 'PLANNED', label: t('batch-state-planned-option') },
        { value: 'IN_PROGRESS', label: t('batch-state-in_progress-option') },
      ]);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [t]);

  const { values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      search: '',
    },
    onSubmit: () => {
      handleSearch();
    },
  });

  const handleSelectState = (options: IMultiSelectOptions) => {
    setSelectedStateValue(options);
    const selectedStates = options.map((option) => option.value);
    setSelectedStates(selectedStates);
  };

  const handleSelectType = (options: IMultiSelectOptions) => {
    setSelectedTypeValue(options);
    const selectedTypes = options.map((option) => option.value);
    setBatches((prevState) => {
      const filteredBathes = prevState?.filter((batch) => selectedTypes.includes(batch.type));
      return filteredBathes?.length ? filteredBathes : batches;
    });
  };

  const handleSearch = () => {
    const filteredBatches = batches?.filter(
      (batch) => batch.name?.includes(values.search) || batch.description?.includes(values.search),
    );
    setBatches(filteredBatches);
    setSelectedStateValue([]);
  };

  const handleClearSearchValue = () => {
    values.search = '';
  };

  return (
    <form
      className="flex justify-between items-center flex-col md:flex-row w-full"
      onSubmit={handleSubmit}
    >
      <SearchField
        value={values.search}
        name="search"
        onChange={handleChange}
        wrapperClassName="md:flex-none w-full md:w-1/2 mb-2 md:mb-0 rounded-[4px] border-[1px] rounded-[4px] border-[1px] "
      />
      <div className="flex flex-col gap-4 w-full md:w-[40%]">
        <div className="flex flex-col gap-4">
          <CustomSelect
            placeholder={t('batch-state-filter-label')}
            isMulti
            value={selectedStateValue}
            options={batchStateOptions}
            onSelect={handleSelectState}
            onChange={handleClearSearchValue}
            customStyle="w-full"
          />
          <CustomSelect
            placeholder={t('batch-type-filter-label')}
            isMulti
            value={selectedTypeValue}
            options={batchTypeOptions}
            onSelect={handleSelectType}
            onChange={handleClearSearchValue}
            customStyle="w-full"
          />
        </div>
        <div>
          <ScanButton
            isOpen={isScannerOpened}
            handleScannerOpen={handleOpenScanner}
            handleScannerClose={handleCloseScanner}
            scannerMode="batch"
            setFieldValue={handleScanFilter}
            buttonText={t('scanner-batch-button', { ns: 'tag' })}
            variant='batch'
          />
        </div>
      </div>
    </form>
  );
};

export default BatchesFilter;
