import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface IOpenContextMenuBtnProps {
  onOpen: () => void;
  icon: React.ReactNode;
}

const OpenContextMenuBtn = ({ onOpen, icon }: IOpenContextMenuBtnProps) => {
  const { isAuthenticated } = useAuth0();

  return (
    <div
      onClick={onOpen}
      className={`fixed ${
        isAuthenticated ? 'bottom-[70px]' : 'bottom-5'
      } right-5 bg-context-menu-bg-color text-white w-[50px] h-[50px] rounded-[50%] flex items-center justify-center cursor-pointer z-[40]`}
    >
      {icon}
    </div>
  );
};

export default OpenContextMenuBtn;
