import imageWarranty from 'src/assets/warranty.png';

interface ITagWarranty {
  expirationDate: string;
}

const TagWarranty = ({ expirationDate }: ITagWarranty) => {
  return (


    <div className="flex justify-center flex-col gap-2 p-3">
      <img src={imageWarranty} alt="warranty" className="w-[100px] h-[100px]" />
      <p className="font-mukta-mahee text-grey-asset text-center font-bold">{expirationDate}</p>
    </div>
  );
};

export default TagWarranty;
