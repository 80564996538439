import React, { useEffect, useState } from 'react';
import { IDocument } from '../../../../../../../../store/services/tagsApi/tagTypes';
import SwiperGallery from './components/SwiperGallery';

interface IImageUploaderProps {
  assetImages: IDocument[];
}

const ImageGallery = ({ assetImages }: IImageUploaderProps) => {
  const [galleryKey, setGalleryKey] = useState(0);

  useEffect(() => {
    setGalleryKey((prevKey) => prevKey + 1);
  }, [assetImages]);

  return (
    <div className="w-full h-full px-8">
      <SwiperGallery key={galleryKey} slides={assetImages} />
    </div>
  );
};

export default React.memo(ImageGallery);
