import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';
import { useGetBatchesQuery } from '../../store/services/batchApi';
import { useGetLocationQuery } from '../../store/services/locationApi';
import { searchFormValidation } from './searchFormValidation';
import SearchField from '../SearchField';

interface ISearchForm {
  isFilter?: boolean;
  searchType?: string;
  searchValue?: string;
  onSubmit?: () => void;
  defaultValue?: string;
}

const SearchForm = ({ isFilter, searchType, searchValue, onSubmit, defaultValue }: ISearchForm) => {
  const history = useHistory();
  const { data: batchesData } = useGetBatchesQuery({});
  const { data: locationsData } = useGetLocationQuery({});

  let searchedBadgeText = '';
  if (searchType === 'batchId') {
    searchedBadgeText = batchesData?.find((batch) => batch.id === Number(searchValue))?.name || '';
  } else if (searchType === 'locationId') {
    searchedBadgeText =
      locationsData?.find((location) => location.id === Number(searchValue))?.name || '';
  } else {
    searchedBadgeText = searchValue || '';
  }

  const handleSearchByTag = (searchValue: string) => {
    history.push(`/tag/${searchValue}`);
  };
  const handleSearchByText = (searchValue: string) => {
    history.push(`/search?search=${searchValue}`);
  };
  const handleFilterAssets = (searchType: string, searchValue: string, search: string) => {
    history.push(`/search?searchType=${searchType}&searchValue=${searchValue}&search=${search}`);
  };

  const { handleSubmit, values, handleChange, touched, errors } = useFormik({
    initialValues: {
      search: defaultValue || '',
    },
    onSubmit: (values) => {
      values.search = values.search.trim()
      const { search } = values;
      if (isFilter && searchType && searchValue) {
        handleFilterAssets(searchType, searchValue, search);
        onSubmit && onSubmit();
      } else {
        if (search[3] === '-' && search.length === 7) {
          handleSearchByTag(search);
        } else {
          handleSearchByText(search);
          onSubmit && onSubmit();
        }
      }
    },
    validationSchema: searchFormValidation(),
  });

  const handleSearchedBadgeRemove = () => {
    history.push(`/search`);
    values.search = '';
  };

  return (
    <form onSubmit={handleSubmit} className="flex items-center justify-between mb-4">
      <SearchField
        name="search"
        wrapperClassName='xl:mb-20 rounded border-2 border-indigo-200'
        handleSubmit={handleSubmit}
        isSearched={isFilter}
        searchedType={searchType}
        searchedText={searchedBadgeText}
        onSearchedBadgeClick={handleSearchedBadgeRemove}
        value={values.search}
        onChange={handleChange}
        isError={!!errors.search && !!touched.search}
        errorMessage={(touched.search && errors?.search) || ''}
      />
    </form>
  );
};

export default SearchForm;
