import { apiRtk } from '../index';
import { REQUEST } from '../../storeTypes';
import { BaseQueryMeta } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { IMarkBody } from './markTypes';

export const markApi = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    markAsset: build.mutation({
      query: ({ body, headers }: IMarkBody) => ({
        url: '/mark',
        method: REQUEST.POST,
        body,
        headers,
      }),
      transformErrorResponse: (error: any, meta: BaseQueryMeta<any>, arg: any) => {
        console.log('log TRK Query ERROR transformErrorResponse ', { error, meta, arg });
      },
    }),
  }),
});

export const { useMarkAssetMutation } = markApi;
