import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { getSelectedCategoryMapping } from '../../../../../../../helpers/categoriesOptions';
import { useGetCategoriesQuery } from '../../../../../../../store/services/categoryApi';
import { IEditAssetBodyData } from '../../../../../../../store/services/assetsApi/assetsTypes';
import SelectSwitcher from "./SelectSwitcher";

interface IAssetCategoryProperty {
  values: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void | FormikErrors<IEditAssetBodyData>>;
}

const CategoryPropertyList = ({ values, setFieldValue }: IAssetCategoryProperty) => {
  const { t, i18n } = useTranslation('tag');
  const { data } = useGetCategoriesQuery({});

  const assetPropertyTypes = useMemo(
    () =>
      data
        ? getSelectedCategoryMapping({
            categories: data,
            selectedCategories: values.categories,
          })
        : [],
    [data, values.categories],
  );

  return assetPropertyTypes.length ? (
    <div className="mt-2">
      {assetPropertyTypes?.map(({ category, categoryProperties }) => {
        const categoryPropertiesKeys = Object.keys(categoryProperties);

        return (
          <div key={category} className=" border border-card px-1 mt-1 mb-2 rounded-md bg-gray-200 xl:w-1/2">
            <div className="flex justify-center pb-1">{`${category} ${t(
              'asset_property_type',
            )}`}</div>
            <div>
              {categoryPropertiesKeys?.map((item) => (
                <SelectSwitcher
                  key={item}
                  item={item}
                  data={data}
                  categoryProperties={categoryProperties}
                  values={values}
                  setFieldValue={setFieldValue}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default React.memo(CategoryPropertyList);
