import { useCallback, useState, useEffect, useMemo } from 'react';
import { IMultiSelectOptions } from '../../../../../../types';
import { ITagData } from '../../../../../../store/services/tagsApi/tagTypes';
import { handleChangeMultiSelect } from '../../../../../../helpers/handleChangeFormik/handleChangeMultiSelect';
import CustomSelect from '../../../../../CustomSelect';
import ScanButton from '../../../../../../views/Home/components/ScanButton';

interface IExternalIdsMultiselect {
  tagData: ITagData | undefined;
  setFieldValue: any;
  placeholder: string; 
}

const ExternalIdsMultiselect = ({
  tagData,
  setFieldValue,
  placeholder, 
}: IExternalIdsMultiselect) => {
  const [isScannerOpen, setIsScannerOpen] = useState<boolean>(false);
  
  const externalIdOptions = useMemo(() => 
    tagData?.externalIds.map((externalId) => ({
      label: externalId.id,
      value: externalId,
    })) || [],
    [tagData]
  );

  const [selectedExternalIds, setSelectedExternalIds] = useState<IMultiSelectOptions>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(false); 

  const handleScannerToggle = useCallback(() => setIsScannerOpen((prevState) => !prevState), []);

  const handleSelectChange = useCallback(
    (options: IMultiSelectOptions) => {
      handleChangeMultiSelect({ option: options, field: 'externalIds', setFieldValue });
      setSelectedExternalIds(options);
    },
    [setFieldValue],
  );


  useEffect(() => {
    setHasValue(selectedExternalIds.length > 0);
  }, [selectedExternalIds]);

  return (
    <div className="relative flex items-center gap-3 w-full">
      <ScanButton
        isOpen={isScannerOpen}
        handleScannerOpen={handleScannerToggle}
        handleScannerClose={handleScannerToggle}
        scannerMode="assetExternal"
        selectedExternalIds={selectedExternalIds}
        setFieldValue={handleSelectChange}
      />
      <div className="relative w-full">
        <CustomSelect
          placeholder=""
          value={selectedExternalIds}
          options={externalIdOptions}
          isMulti
          isSearchable
          onSelect={handleSelectChange}
          hasHorizontalScroll
          customStyle="w-full"
          onFocus={() => setIsFocused(true)} 
          onBlur={() => setIsFocused(false)} 
        />
        <label
          className={`absolute left-[10px] transition-all duration-100 ease-linear pointer-events-none text-place-holder ${
            hasValue || isFocused ? '!-top-1 text-xs' : 'top-[50%] -translate-y-1/2 text-base'
          }`}
          style={{ width: 'calc(100% - 40px)' }}
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};

export default ExternalIdsMultiselect;
