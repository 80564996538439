import React, { useState, useCallback, useEffect } from 'react';
import { IDocument } from 'src/store/services/tagsApi/tagTypes';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconDelete } from 'src/assets/footer-menu/IconDelete.svg';
import ImageScanner from 'src/components/ImageScanner';

interface AssetPictureProps {
    assetId: number; 
    capturedImages: IDocument[];
    setCapturedImages: React.Dispatch<React.SetStateAction<IDocument[]>>;
    dataDocuments?: IDocument[];
}

const AssetPicture: React.FC<AssetPictureProps> = ({
    assetId,
    capturedImages,
    setCapturedImages,
    dataDocuments = [],
}) => {
    const { t } = useTranslation('tag');
    const [isImageScannerOpen, setIsImageScannerOpen] = useState(false);

    useEffect(() => {
        const fetchAssetData = async () => {
            try {
                const response = await fetch(`/assets/v1/asset/${assetId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch asset data');
                }
                const data = await response.json();
                const tagImages = data.documents.filter((doc: IDocument) => doc.documentType === 'IMAGE');
                setCapturedImages(tagImages);
            } catch (error) {
                console.error('Error fetching asset data:', error);
            }
        };

        fetchAssetData();
    }, [assetId, setCapturedImages]);

    const handleDeleteImage = (index: number) => {
        setCapturedImages(prevState => prevState.filter((_, i) => i !== index));
    };

    const handleCloseCreatingModal = useCallback(() => {
        setIsImageScannerOpen(false);
    }, []);

    const addLocalImage = useCallback(
        (img: string) => {
            setCapturedImages(prevState => [
                ...prevState,
                { url: img, documentType: 'IMAGE' },
            ]);
        },
        [setCapturedImages]
    );

    const moveImage = (index: number, direction: 'left' | 'right') => {
        setCapturedImages(prevState => {
            const newImages = [...prevState];
            const targetIndex = direction === 'left' ? index - 1 : index + 1;

            // Swap images
            [newImages[index], newImages[targetIndex]] = [newImages[targetIndex], newImages[index]];
            return newImages;
        });
    };

    return (
        <div>
            <label className="block text-gray-500 text-sm font-medium mb-0 pl-3 -mb-4">
                {t('asset-images-label')}
            </label>
            <div className="mt-4 p-4 border border-gray-300 rounded-lg">
                <div className="flex gap-8 flex-wrap justify-center">
                    {capturedImages.map((img, index) => (
                        <div key={index} className="relative">
                            <img
                                src={img.url}
                                alt={`Asset image ${index + 1}`} 
                                className="w-32 h-32 object-cover border-2 rounded cursor-pointer border-gray-300"
                            />
                            <span className="absolute top-1 left-1 bg-white text-black font-bold px-1 rounded">
                                {index + 1}
                            </span>
                            <div className="flex justify-between mt-2">
                                <button
                                    type="button"
                                    className="bg-gray-300 p-1 rounded disabled:opacity-50"
                                    onClick={() => moveImage(index, 'left')}
                                    disabled={index === 0}
                                    aria-label={`Move image ${index + 1} left`}
                                >
                                    ←
                                </button>
                                <button
                                    type="button"
                                    className="bg-gray-300 p-1 rounded disabled:opacity-50"
                                    onClick={() => moveImage(index, 'right')}
                                    disabled={index === capturedImages.length - 1}
                                    aria-label={`Move image ${index + 1} right`}
                                >
                                    →
                                </button>
                            </div>
                            <div className="flex justify-center mt-2">
                                <button
                                    type="button"
                                    className="flex items-center gap-1 bg-red-500 text-white p-1 rounded"
                                    onClick={() => handleDeleteImage(index)} 
                                    aria-label={`Delete image ${index + 1}`}
                                >
                                    <IconDelete className="w-4 h-4 fill-white" />
                                    <span className="text-sm">{t('Delete')}</span>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isImageScannerOpen && (
                <ImageScanner
                    onClose={handleCloseCreatingModal}
                    addLocalImage={addLocalImage}
                    assetId={assetId}
                />
            )}
        </div>
    );
};

export default AssetPicture;
