import React from 'react';
import { ReactComponent as IconClose } from 'src/assets/IconClose.svg';
import { useTranslation } from 'react-i18next';

interface CloseButtonProps {
  onClose: () => void;
  disabled?: boolean;
  label?: string; 
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose, disabled = false, label }) => {
  const { t } = useTranslation('common'); 

  return (
    <button
      type="button"
      onClick={onClose}
      disabled={disabled}
      className={`flex items-center justify-center gap-1 w-[160px] py-[10px] h-[52px] rounded text-lg leading-normal text-red-600 bg-white border-2 border-solid border-red-600 cursor-pointer ml-20     ${
        disabled ? 'opacity-50 cursor-not-allowed' : 'transition-colors duration-300 ease-linear'
      }`}
    >
      <IconClose className="w-[1.6rem] h-[1.6rem] fill-red-600" />
      <span>{label || t('form-button-close')}</span>
    </button>
  );
};

export default CloseButton;
