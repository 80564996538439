import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import commonEn from '../locales/en/common.json';
import commonNo from '../locales/no/common.json';
import tagEn from '../locales/en/tag.json';
import tagNo from '../locales/no/tag.json';
import layoutEn from '../locales/en/layout.json';
import layoutNo from '../locales/no/layout.json';
import batchesEn from '../locales/en/batches.json';
import batchesNo from '../locales/no/batches.json';
import locationEn from '../locales/en/location.json';
import locationNo from '../locales/no/location.json';
import landingEn from '../locales/en/landing.json';
import landingNo from '../locales/no/landing.json';
import searchEn from '../locales/en/search.json';
import searchNo from '../locales/no/search.json';
import eventsEn from '../locales/en/events.json';
import eventsNo from '../locales/no/events.json';
import serviceEn from '../locales/en/service.json';
import serviceNo from '../locales/no/service.json';

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources: {
      en: {
        common: commonEn,
        tag: tagEn,
        layout: layoutEn,
        batches: batchesEn,
        location: locationEn,
        landing: landingEn,
        search: searchEn,
        events: eventsEn,
        service: serviceEn,
      },
      no: {
        common: commonNo,
        tag: tagNo,
        layout: layoutNo,
        batches: batchesNo,
        location: locationNo,
        landing: landingNo,
        search: searchNo,
        events: eventsNo,
        service: serviceNo,
      },
    },
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    debug: true,
    fallbackLng: 'no',
  });

export default i18n;
