import React, { Suspense, lazy, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type ICommand = {
  key: string;
  command: () => void;
};

type MDEditorModule = {
  default: React.FC<any>;
  commands: { [key: string]: ICommand | any }; 
};

const MDEditor = lazy(() =>
  import('@uiw/react-md-editor').then((module) => {
    return {
      default: module.default,
      commands: module.commands as unknown, 
    } as MDEditorModule; 
  })
);

interface MDEditorProps {
  id: string;
  value: string;
  onChange: (value: string | undefined) => void;
  label: string;
  error?: string;
  touched?: boolean;
  customCommands?: ICommand[];
}

const CustomMDEditor: React.FC<MDEditorProps> = ({
  id,
  value,
  onChange,
  label,
  error,
  touched,
  customCommands = [],
}) => {
  const { t } = useTranslation('batches');
  const [commands, setCommands] = useState<{ [key: string]: ICommand | any } | null>(null); 

  useEffect(() => {
    const loadCommands = async () => {
      const module = await import('@uiw/react-md-editor');
      setCommands(module.commands as { [key: string]: ICommand | any }); 
    };

    loadCommands();
  }, []);

  if (!commands) {
    return <div>Loading commands...</div>;
  }

  const defaultCommands: ICommand[] = [
    commands.bold,
    commands.italic,
    commands.strikethrough,
    commands.hr,
    commands.title,
    commands.link,
    commands.quote,
    commands.unorderedListCommand,
    commands.orderedListCommand,
    commands.checkedListCommand,
    commands.help,
  ];

  const allCommands = [...defaultCommands, ...customCommands];

  return (
    <div className="mb-8">
      <label htmlFor={id} className="block text-gray-500 text-sm font-medium mb-1 pl-2">
        {t(label)}
      </label>
      <div data-color-mode="light" className="markdown-editor mb-2">
        <Suspense fallback={<div>Loading editor...</div>}>
          <MDEditor
            id={id}
            value={value}
            onChange={onChange}
            commands={allCommands}
          />
        </Suspense>
      </div>
      {touched && error && (
        <div className="error-message">{error}</div>
      )}
    </div>
  );
};

export default CustomMDEditor;
