import React, { ReactNode } from 'react';

interface IFormWrapper {
  onSubmit: () => void;
  children: ReactNode;
}

const FormWrapper = ({ onSubmit, children }: IFormWrapper) => {
  return (
    <form onSubmit={onSubmit} className=" w-full overflow-y-auto  h-full ">
      <div className="flex items-center h-full p-2">
        <div className="m-auto w-full xl:w-4/5">{children}</div>
      </div>
    </form>
  );
};

export default FormWrapper;
