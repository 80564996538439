import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'src/constants/routes';
import { ReactComponent as IconHome } from 'src/assets/footer-menu/IconHome.svg';
import { ReactComponent as IconLayers } from 'src/assets/footer-menu/IconLayers.svg';
import { ReactComponent as IconLocation } from 'src/assets/footer-menu/IconLocation.svg';
import { ReactComponent as IconSearch } from 'src/assets/footer-menu/IconSearch.svg';
import ButtonWithIcon from 'src/components/ButtonWithIcon';
import { ReactComponent as IconQR } from 'src/assets/home/IconQR.svg';
import { themeConfigs } from '../constants/themeConfigs';
import { useAuth0 } from '@auth0/auth0-react';  

interface NavigationProps {
  handleScannerOpen: () => void;
  currentTheme: string;
}

const Navigation: React.FC<NavigationProps> = ({
  handleScannerOpen,
  currentTheme,
}) => {
  const { t } = useTranslation('layout');
  const location = useLocation();

  const activeColor = themeConfigs[currentTheme].bottomMenuActiveColor;
  const foregroundColor = themeConfigs[currentTheme].bottomMenuForegroundColor;

  const matchRoute = (route: string) => location.pathname === route;

  const { isAuthenticated } = useAuth0();


  return isAuthenticated ? (
      <nav className="fixed bottom-0 left-0 xl:relative  xl:inset-0 w-full flex justify-around items-center h-[60px] pb-[10px] bg-bottom-menu-bg-color xl:bg-white z-50 xl:border-b-2 xl:border-gray-200 xl:mb-20 xl:p-10">
        <p className="hidden xl:block font-['arg'] text-3xl">SIRK:tag</p>
        <Link 
          to={ROUTES.HOME} 
          className="flex flex-col items-center justify-center leading-4 text-[--bottomMenuForegroundColor]  xl:text-[--bottom-menu-background-color] xl:font-medium xl:text-xl " 
        >
          <IconHome 
            className="w-6 h-6 xl:hidden" 
            style={{ fill: matchRoute(ROUTES.HOME) ? activeColor : foregroundColor }} 
          />
          {t('home')}
        </Link>
        
        <Link 
          to={ROUTES.SEARCH} 
          className="flex flex-col items-center justify-center leading-4 text-[--bottomMenuForegroundColor] xl:text-[--bottom-menu-background-color] xl:font-medium xl:text-xl" 
        >
          <IconSearch 
            className="w-6 h-6 xl:hidden" 
            style={{ fill: matchRoute(ROUTES.SEARCH) ? activeColor : foregroundColor }} 
          />
          {t('bottom-nav-search')}
        </Link>
        <ButtonWithIcon
          className={`flex flex-col items-center justify-center leading-4 xl:hidden bg-[--bottomMenuActiveColor] pb-4 p-2 md:p-4 px-5 md:px-8  text-white rounded-t-full `}
          icon={<IconQR className="w-6 h-6 md:w-10 md:h-10 fill-white mb-2" />}
          text={t('context-menu-scan-label', { ns: 'tag' })}
          onClick={handleScannerOpen}
        />
        <Link 
          to={ROUTES.BATCHES} 
          className="flex flex-col items-center justify-center leading-4 text-[--bottomMenuForegroundColor]  xl:text-[--bottom-menu-background-color] xl:font-medium xl:text-xl"
        >
          <IconLayers 
            className="w-6 h-6 xl:hidden" 
            style={{ fill: matchRoute(ROUTES.BATCHES) ? activeColor : foregroundColor }} 
          />
          {t('batch')}
        </Link>
        <Link 
          to={ROUTES.LOCATION} 
          className="flex flex-col items-center justify-center leading-4 text-[--bottomMenuForegroundColor]  xl:text-[--bottom-menu-background-color] xl:font-medium xl:text-xl"
        >
          <IconLocation 
            className="w-6 h-6 xl:hidden" 
            style={{ fill: matchRoute(ROUTES.LOCATION) ? activeColor : foregroundColor }} 
          />
          {t('location')}
        </Link>
        
      </nav>
  ) : (
    <nav className="fixed bottom-0 left-0 xl:relative  xl:inset-0 w-full flex justify-around items-center h-[60px] pb-[10px] bg-bottom-menu-bg-color xl:bg-white z-50 xl:border-b-2 xl:border-gray-200 xl:mb-20 xl:p-10">
    <p className="hidden xl:block font-['arg'] text-3xl">SIRK:tag</p>
   
  </nav>
  );
};

export default Navigation;
