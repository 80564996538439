import React, { useCallback, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../helpers/reduxCommon';
import { useGrantWarrantyMutation } from '../../store/services/warrantyApi';
import { useEditBatchMutation } from '../../store/services/batchApi';
import { usePostAssetByIdMutation } from '../../store/services/tagsApi';
import { useDeleteAssetMutation } from '../../store/services/assetsApi';
import { useDeleteLocationMutation } from '../../store/services/locationApi';
import { useLazyGetAssetServiceQuery } from '../../store/services/configApi';

import { ROUTES } from '../../constants/routes';
import { batchState, IBatchData } from '../../store/services/batchApi/batchTypes';
import { IDocument, ITagData } from '../../store/services/tagsApi/tagTypes';
import { ILocationData } from '../../store/services/locationApi/locationTypes';
import { getXAuthOwnerHeader } from '../../helpers/getXAuthOwnerHeader';
import { createFormDataFile } from '../../helpers/createFormDataFile';
import { JwtDecodeUsers } from '../../helpers/JwtDecodeUsers';

import ContextMenu from 'src/components/Layout/components/ContextMenu';
import OpenContextMenuBtn from 'src/components/Layout/components/ContextMenu/components/OpenContextMenuBtn';
import ModalContainer from './components/ModalContainer';
import ImageScanner from '../ImageScanner';
import EditBatchModal from './Batch/EditBatchModal';
import EditLocationModal from './Location/EditLocation';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import ScanButton from 'src/views/Home/components/ScanButton';

import { ReactComponent as IconAdd } from 'src/assets/footer-menu/IconAdd.svg';
import { ReactComponent as IconMore } from 'src/assets/IconMore.svg';
import { ReactComponent as IconEdit } from 'src/assets/EditItem.svg';
import { ReactComponent as IconStart } from 'src/assets/IconStart.svg';
import { ReactComponent as IconDone } from 'src/assets/IconDone.svg';
import { ReactComponent as IconHome } from 'src/assets/footer-menu/IconHome.svg';
import { ReactComponent as IconDelete } from 'src/assets/footer-menu/IconDelete.svg';
import { ReactComponent as IconBuild } from 'src/assets/footer-menu/IconBuild.svg';
import { ReactComponent as IconChecklist } from 'src/assets/footer-menu/IconChecklist.svg';
import { ReactComponent as IconLicense } from 'src/assets/footer-menu/IconLicense.svg';
import { ReactComponent as IconQR } from '../../assets/home/IconQR.svg';

interface IContextMenuModal {
  editBatchData?: IBatchData;
  editAssetData?: ITagData;
  editLocationData?: ILocationData;
  modalType?: 'general' | 'batch' | 'asset' | 'location';
  setLocalTagImages?: React.Dispatch<React.SetStateAction<[] | IDocument[]>>;
}

type IOpenedModalType =
  | ''
  | 'batchExisting'
  | 'batchFromObjects'
  | 'editBatch'
  | 'editBatchAssets'
  | 'editAsset'
  | 'editLocation'
  | 'deleteAsset'
  | 'deleteBatch'
  | 'deleteLocation'
  | 'imageUploader';

const ContextMenuModal = ({
  modalType = 'general',
  editBatchData,
  editAssetData,
  editLocationData,
  setLocalTagImages,
}: IContextMenuModal) => {
  const { t } = useTranslation('layout');
  const { isAuthenticated } = useAuth0();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [openedModalType, setOpenedModalType] = useState<IOpenedModalType>('');
  const [editBatch] = useEditBatchMutation();
  const [postAssetById] = usePostAssetByIdMutation();
  const [deleteAssetById] = useDeleteAssetMutation();
  const [deleteLocationById] = useDeleteLocationMutation();
  const [getAssetService] = useLazyGetAssetServiceQuery();
  const [grantWarranty] = useGrantWarrantyMutation();
  const [isScannerOpened, setIsScannerOpened] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const token = useAppSelector((state) => state.auth.token);
  const isServiceProvider = !!JwtDecodeUsers(token, 'https://sirk.app/roles').find(
    (role) => role.label === 'serviceprovider',
  );
  const isAssetOwner =
    isAuthenticated &&
    !!JwtDecodeUsers(token).find(
      (organization) => organization.value === editAssetData?.owner.name,
    );

  const handleToggleOpenContextMenu = useCallback(() => {
    setIsContextMenuOpen((prevState) => !prevState);
  }, []);

  const handleCloseCreatingModal = useCallback(() => {
    setOpenedModalType('');
  }, []);

  const handleUploadFileBtnClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);


  const handleOpenScanner = useCallback(() => setIsScannerOpened(true), []);
  const handleCloseScanner = useCallback(() => setIsScannerOpened(false), []);

  const contextItems = useMemo(() => {
 
    const batchAction = (state: batchState) =>
      editBatch({
        batchId: editBatchData?.id,
        body: { state },
        ...getXAuthOwnerHeader(editBatchData?.owner.name || ''),
      });

    const batchServiceProviderContextItems = [
      {
        icon: IconLicense,
        text: t('context-menu-grant-warranty-label', { ns: 'batches' }),
        onClick: () => {
          grantWarranty({
            body: {
              batchId: editBatchData?.id,
              duration: 'THREE_YEARS',
            },
            ...getXAuthOwnerHeader(editBatchData?.owner?.name || ''),
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
      },
    ];

    const batchContextItems = [
      {
        icon: IconEdit,
        text: t('context-label-edit', { ns: 'batches' }),
        onClick: () => setOpenedModalType('editBatch'),
      },
      ...(isServiceProvider ? batchServiceProviderContextItems : []),
      {
        icon: IconChecklist,
        text: t('edit-assets', { ns: 'batches' }),
        onClick: () => setOpenedModalType('editBatchAssets'),
      },
      ...(editBatchData?.state === 'PLANNED'
        ? [
            {
              icon: IconStart,
              text: t('context-label-start', { ns: 'batches' }),
              onClick: () => batchAction('IN_PROGRESS'),
            },
            {
              icon: IconDone,
              text: t('context-label-close', { ns: 'batches' }),
              onClick: () => batchAction('COMPLETED'),
            },
          ]
        : editBatchData?.state === 'IN_PROGRESS'
          ? [
              {
                icon: IconDone,
                text: t('context-label-close', { ns: 'batches' }),
                onClick: () => batchAction('COMPLETED'),
              },
            ]
          : [
              {
                icon: IconStart,
                text: t('context-label-restart', { ns: 'batches' }),
                onClick: () => batchAction('IN_PROGRESS'),
              },
            ]),
    ];

    const assetContextItems = [
      {
        icon: IconHome,
        text: t('context-menu-home-label', { ns: 'tag' }),
        onClick: () => history.push(ROUTES.HOME),
      },
      {
        icon: IconQR,
        text: t('context-menu-scan-label', { ns: 'tag' }),
        onClick: handleOpenScanner,
      },
    ];

    const assetServiceProviderContextItems = [
      {
        icon: IconLicense,
        text: t('context-menu-grant-warranty-label', { ns: 'tag' }),
        onClick: () => {
          grantWarranty({
            body: {
              assetId: editAssetData?.id,
              duration: 'THREE_YEARS',
            },
            ...getXAuthOwnerHeader(editAssetData?.owner?.name || ''),
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        },
      },
    ];

    const authenticatedAssetContextItems = [
      {
        icon: IconEdit,
        text: t('context-menu-edit-label', { ns: 'tag' }),
        onClick: () => setOpenedModalType('editAsset'),
      },
      ...(isServiceProvider ? assetServiceProviderContextItems : []),
    ];

    const locationContextItems = [
      {
        icon: IconEdit,
        text: t('context-menu-edit-label', { ns: 'location' }),
        onClick: () => setOpenedModalType('editLocation'),
      },
      {
        icon: IconDelete,
        text: t('context-menu-delete-label', { ns: 'location' }),
        onClick: () => setOpenedModalType('deleteLocation'),
        deleteMode: true,
      },
    ];

    switch (modalType) {
      case 'batch':
        return batchContextItems;
      case 'asset':
        return isAssetOwner ? authenticatedAssetContextItems : assetContextItems;
      default:
        return locationContextItems;
    }
  }, [
    isAssetOwner,
    modalType,
    editBatchData,
    t,
    editBatch,
    history,
    isServiceProvider,
    grantWarranty,
    editAssetData?.owner?.name,
    editAssetData?.id,
    handleOpenScanner
  ]);

  const addLocalImage = useCallback(
    (img: string) => {
      setLocalTagImages?.((prevState) => [...prevState, { url: img, documentType: 'IMAGE' }]);
    },
    [setLocalTagImages],
  );

  const handleLoadFile = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const inputFile = ev.target.files ? ev.target.files[0] : null;
      const isImageFileType = inputFile?.type.startsWith('image');

      const onLoadFile = (formData: FormData) => {
        postAssetById({ assetId: editAssetData?.id, body: formData });
      };

      createFormDataFile({
        documentType: isImageFileType ? 'IMAGE' : 'DOCUMENT',
        event: ev,
        onLoadFile,
        ...(isImageFileType && addLocalImage),
      });
    },
    [editAssetData?.id, postAssetById, addLocalImage],
  );

  const handleAcceptDeleting = () => {
    switch (openedModalType) {
      case 'deleteAsset':
        deleteAssetById(editAssetData?.id);
        setOpenedModalType('');
        break;
      case 'deleteLocation':
        deleteLocationById(editLocationData?.id);
        setOpenedModalType('');
        history.push(ROUTES.LOCATION);
        break;
    }
  };

  return (
    <div>
      <OpenContextMenuBtn
        onOpen={handleToggleOpenContextMenu}
        icon={
          modalType !== 'general' ? (
            <IconMore className="w-6 h-6 fill-context-menu-foreground-color" />
          ) : (
            <IconAdd className="w-6 h-6 fill-context-menu-foreground-color" />
          )
        }
      />
      {isContextMenuOpen && (
        <ContextMenu onClose={handleToggleOpenContextMenu} items={contextItems} />
      )}
      
      <EditBatchModal
        onClose={handleCloseCreatingModal}
        isOpened={openedModalType === 'editBatch'}
        data={editBatchData}
      />
      <EditBatchModal
        onClose={handleCloseCreatingModal}
        isOpened={openedModalType === 'editBatchAssets'}
        data={editBatchData}
        editMode="editBatchAssets"
      />

      <EditLocationModal
        onClose={() => setOpenedModalType('')}
        isOpened={openedModalType === 'editLocation'}
        data={editLocationData}
      />
      <ConfirmationModal
        isOpen={
          openedModalType === 'deleteAsset' ||
          openedModalType === 'deleteBatch' ||
          openedModalType === 'deleteLocation'
        }
        onCancel={() => {
          setOpenedModalType('');
        }}
        onClose={() => {
          setOpenedModalType('');
        }}
        onAccept={handleAcceptDeleting}
        confirmationText={t('confirmation-text')}
        confirmationAcceptText={t('confirmation-accept-text')}
        confirmationCancelText={t('confirmation-cancel-text')}
      />
      
      <input ref={fileInputRef} className="hidden" type="file" onChange={handleLoadFile} />
      {isScannerOpened && (
        <ScanButton
          isOpen={isScannerOpened}
          handleScannerOpen={handleOpenScanner}
          handleScannerClose={handleCloseScanner}
        />
      )}
    </div>
  );
};

export default React.memo(ContextMenuModal);
